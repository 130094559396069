import React from 'react'
import RequestBrochure from './RequestBrochure';
const Section2 = () => {

    return (
        <>
            <section className="section-two-bg d-lg-block d-md-block d-sm-none d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="section-two-overlay pt-50 pb-50 pt-xs-20 pb-xs-20 pt-sm-20 pb-sm-20 wow fadeIn"
                                data-wow-delay="0.1s"
                            >
                                <div
                                    className="section-one-padding wow fadeIn"
                                    data-wow-delay="0.2s"
                                >
                                    <h4 className="mont-reg fs-xs-20 fs-sm-20 text-center mb-30 mb-xs-10 mb-sm-10">
                                        <b>The Pursuit Of A Radical Rhapsody</b>
                                    </h4>
                                    <p className="mont-reg fs-16 text-justify">
                                        Pursuit of a Radical Rhapsody is designed in the pursuit of a
                                        new idea – the idea of having a tree in your terrace garden.
                                        Each garden in our L30 and L45 apartments, has been designed to
                                        house a tree with the help of a specially developed tree tub -
                                        as high as the 27th floor!
                                        <br />
                                        <br />
                                        The property is located on the edge of a small lake in
                                        Whitefield - the IT hub of India’s Silicon Valley, in the
                                        vicinity of the best international schools, hospitals, malls and
                                        five-star hotels, within a 3 minute walk from the Forum Mall,
                                        Manipal Hospital and the Sheraton Hotel. Whitefield’s urbane
                                        culture and lifestyle have made it home to well-travelled
                                        professionals.
                                        <br />
                                        <br />
                                        Against the backdrop of Bangalore’s glorious weather, this
                                        burgeoning suburb is one of the best investment destinations in
                                        India.
                                    </p>
                                    <RequestBrochure/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="d-lg-none d-md-none d-sm-block d-block">
                <div className="wow fadeIn" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 padding-0">
                                        <div className="section-two-bg col-12" />
                                        <div
                                            className="section-two-overlay section-one-padding wow fadeIn"
                                            data-wow-delay="0.2s"
                                        >
                                            <h3 className="mont-reg fs-xs-20 fs-sm-20 text-center mb-30 mb-xs-10 mb-sm-10">
                                                <b>The Pursuit Of A Radical Rhapsody</b>
                                            </h3>
                                            <p className="mont-reg fs-14 text-justify">
                                                Pursuit of a Radical Rhapsody is designed in the pursuit of
                                                a new idea – the idea of having a tree in your terrace
                                                garden. Each garden in our L30 and L45 apartments, has been
                                                designed to house a tree with the help of a specially
                                                developed tree tub - as high as the 27th floor!
                                                <br />
                                                <br />
                                                The property is located on the edge of a small lake in
                                                Whitefield - the IT hub of India’s Silicon Valley, in the
                                                vicinity of the best international schools, hospitals, malls
                                                and five-star hotels, within a 3 minute walk from the Forum
                                                Mall, Manipal Hospital and the Sheraton Hotel. Whitefield’s
                                                urbane culture and lifestyle have made it home to
                                                well-travelled professionals.
                                                <br />
                                                <br />
                                                Against the backdrop of Bangalore’s glorious weather, this
                                                burgeoning suburb is one of the best investment destinations
                                                in India.
                                            </p>
                                            <RequestBrochure/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="d-lg-none d-md-none d-sm-block d-block">
                <div className="wow fadeIn" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className=" col-lg-6 col-12" />
                                    <div className="col-lg-6 col-12">
                                        <div
                                            className="section-one-padding wow fadeIn"
                                            data-wow-delay="0.2s"
                                        >
                                            <h4 className="mont-reg fs-xs-20 fs-sm-20 mb-10 mb-xs-10 mb-sm-10">
                                                <b>The Pursuit Of A Radical Rhapsody</b>
                                            </h4>
                                            <p className="mont-reg fs-14 text-justify">
                                                Pursuit of a Radical Rhapsody is designed in the pursuit of
                                                a new idea – the idea of having a tree in your terrace
                                                garden. Each garden in our L30 and L45 apartments, has been
                                                designed to house a tree with the help of a specially
                                                developed tree tub - as high as the 27th floor!
                                                <br />
                                                <br />
                                                The property is located on the edge of a small lake in
                                                Whitefield - the IT hub of India’s Silicon Valley, in the
                                                vicinity of the best international schools, hospitals, malls
                                                and five-star hotels, within a 3 minute walk from the Forum
                                                Mall, Manipal Hospital and the Sheraton Hotel. Whitefield’s
                                                urbane culture and lifestyle have made it home to
                                                well-travelled professionals.
                                                <br />
                                                <br />
                                                Against the backdrop of Bengaluru's glorious weather, this
                                                burgeoning suburb is one of the best investment destinations
                                                in India.
                                            </p>
                                            <div className="text-center">
                                                <div className="d-none d-sm-none d-md-block d-lg-block ">
                                                    <RequestBrochure />
                                                </div>
                                                <div className="d-block d-sm-block d-md-none d-lg-none">
                                                    <RequestBrochure />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section2
