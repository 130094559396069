import React from 'react'

const Dummydiv = () => {
  return (
    <>
      <div className="dummy-div d-block d-sm-block d-md-none d-lg-none" />
    </>
  )
}

export default Dummydiv
