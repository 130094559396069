import React from "react";
import Home from './component/Home';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const App = () => {
  return (
  <>
    <Home/>
  </>
  );
};

export default App